import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { FileuploadService } from 'src/app/services/fileupload.service';

@Component({
  selector: 'app-vendor-registration',
  templateUrl: './vendor-registration.component.html',
  styleUrls: ['./vendor-registration.component.css']
})
export class VendorRegistrationComponent implements OnInit {
  myForm!: FormGroup;
  savedData: any = localStorage.getItem('');
  formData: FormData = new FormData();
  fileInput: any = '';
  file: File;
  currencyList: Array<any> = [];
  editData: any;
  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private route: Router, private CommonService: CommonService, private fileuploadService: FileuploadService) { }


  ngOnInit(): void {
    this.formInIt(); this.currencyDropdown();
    this.loadData();
  }
  formInIt() {
    this.myForm = this.formBuilder.group({
      organizationName: [localStorage.getItem('Username')],
      emailId: [atob(localStorage.getItem('Email'))],
      mobileNo: [atob(localStorage.getItem('mNo'))],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      org_address: ['', Validators.required],
      vendor_code: ['', Validators.required],
      org_reg_no: ['', Validators.required],
      kra_no: ['', Validators.required],
      upload_proof: ['', Validators.required],
      bank_name: ['', Validators.required],
      account_no: ['', Validators.required],
      branch: ['', Validators.required],
      vreg_currency_id: ['', Validators.required]
    });
  }

  loadData() {
    this.CommonService.activateSpinner();
    this.CommonService.getCall(`FinanceVendor/GetVendorRegistrationById/${localStorage.TenantCode}/${localStorage.UserId}`).subscribe((res: any) => {
      console.log(res);
      if (res.data === null) {
        this.loadParameters();
      } else {
        this.editData = res.data;
        this.fileInput = this.editData.vreg_document_path
        this.myForm.patchValue({
          organizationName: this.editData.vreg_name,
          emailId: this.editData.vreg_email,
          mobileNo: this.editData.vreg_mobile,
          first_name: this.editData.vreg_first_name,
          last_name: this.editData.vreg_last_name,
          org_address: this.editData.vreg_address,
          vendor_code: this.editData.vreg_code,
          org_reg_no: this.editData.vreg_Registration_no,
          kra_no: this.editData.vreg_kra_no,
          // upload_proof: this.editData.vreg_document_path,
          bank_name: this.editData.vreg_bank_name,
          account_no: this.editData.vreg_account_no,
          branch: this.editData.vreg_branch,
          vreg_currency_id: this.editData.vreg_country_id
        })
      }
      this.CommonService.deactivateSpinner();
    }, e => { this.CommonService.deactivateSpinner(); })
  }

  loadParameters() {
    this.CommonService.activateSpinner();
    this.CommonService.getCall(`Parameter/GetList/${localStorage.TenantCode}/${11}`).subscribe((res: any) => {
      let sequence_id = res.data.find((e: any) => e.par_reference == 'Vendor Code')?.par_squence_id;
      if (sequence_id == undefined) {
        this.toastr.warning('Vendor Code is not assigned');
      } else {
        this.bindPurchaseOrder(sequence_id);
      }
      this.CommonService.deactivateSpinner();
    }, e => { this.CommonService.deactivateSpinner(); })

  }

  currencyDropdown() {
    this.CommonService.activateSpinner();
    let payload = {
      "DictionaryCode": "465154cd"
    }
    this.CommonService.postCall('DataDictionary/GetDictionaryByKey', payload).subscribe((res: any) => {
      this.currencyList = res;
      this.CommonService.deactivateSpinner();
    }, e => { this.CommonService.deactivateSpinner(); })
  }

  bindPurchaseOrder(sId: any) {
    this.CommonService.activateSpinner();
    this.CommonService.getCall(`SequenceNumber/GetSequenceNumber/${localStorage.TenantCode}/${sId}`).subscribe((res: any) => {
      if (res.data.length) {
        this.myForm.patchValue({ vendor_code: res.data[0].seqnumber })
      } else {
        this.toastr.info('Vendor Code is not genarted', 'Please try again')
      }
      this.CommonService.deactivateSpinner();
    }, e => { this.CommonService.deactivateSpinner(); })
  }

  onFileChange(event: any) {
    this.CommonService.activateSpinner();
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      if (this.file.type !== 'application/pdf') {
        this.toastr.warning('Only PDF files are allowed!');
        event.target.value = '';
        this.CommonService.deactivateSpinner();
        return;
      }
      // this.myForm.patchValue({ upload_proof: this.file.name })
    }
    this.CommonService.deactivateSpinner();
  }

  private createFormData(): FormData {
    let data = this.myForm.getRawValue();
    const formData = new FormData();
    formData.append('vreg_name', data.organizationName);
    formData.append('vreg_email', data.emailId);
    formData.append('vreg_mobile', data.mobileNo);
    formData.append('vreg_first_name', data.first_name);
    formData.append('vreg_last_name', data.last_name);
    formData.append('vreg_address', data.org_address);
    formData.append('vreg_code', data.vendor_code);
    formData.append('vreg_Registration_no', data.org_reg_no);
    formData.append('vreg_kra_no', data.kra_no);
    formData.append('vreg_document_path', this.file);
    formData.append('vreg_bank_name', data.bank_name);
    formData.append('vreg_account_no', data.account_no);
    formData.append('vreg_branch', data.branch);
    formData.append('status', 'false');
    formData.append('created_by', localStorage.UserId);
    formData.append('tnt_code', localStorage.TenantCode);
    formData.append('modified_by', localStorage.UserId);
    formData.append('vreg_user_id', localStorage.UserId);
    formData.append('vreg_currency_id', data.vreg_currency_id);
    formData.append('url', location.origin.includes("localhost") ? "https://oukdev.samvaadpro.com/vendorlogin" : location.origin + '/vendorlogin');
    // formData.append('registration_id', this.editData.registration_id || 0);
    return formData;
  }

  submit(form: any) {
    this.CommonService.activateSpinner();
    const formData = this.createFormData();
    console.log(formData);
    this.fileuploadService.upload(formData, 'FinanceVendor/VendorReistration').subscribe(res => {
      if (res.status === true) {
        this.toastr.success('Registration Created successfully');
        this.file = null;
        // this.route.navigate(['/HOME'])
        this.close();
      } else if (res.status === false) {
        this.toastr.warning(res.message);
      }
      this.CommonService.deactivateSpinner();
    }, error => {
      if (error.status === 400) {
        this.toastr.error(error.error);
      } else {
        this.toastr.error('An unexpected error occurred.');
      }
      this.CommonService.deactivateSpinner();
    });
  }




  close() {
    this.clearToken();
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.route.navigate(['/vendorlogin']);
    }, 500);
  }
  clearToken() {
    this.CommonService.postCall('Account/ClearToken', { "UserID": localStorage.getItem('UserId') }).subscribe((res: any) => {

    })
  }


  viewFile() {
    if (this.fileInput == '') {
      this.toastr.warning('File not available');
    } else {
      window.open(this.CommonService.videoUrl + this.fileInput);
    }
  }

}
