import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchDropdownComponent } from '../search-dropdown/search-dropdown.component';

@NgModule({
  declarations: [
    SearchDropdownComponent
  ],
  imports: [
    CommonModule,FormsModule
  ],
  exports: [
    SearchDropdownComponent
  ]
})
export class SharedModule { }
